import { HttpGet } from "../../http/HttpClient";
import { PlayerEnergyEventEmitter, PlayerLevelEventEmitter } from "../../event/event";
import { GetLocale } from "../../language";

export const decodeToJson = (data) => {
  const binaryString = atob(data)

  const binaryLength = binaryString.length;
  const bytes = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }

  const decodedString = new TextDecoder().decode(bytes);
  // console.log(decodedString)
  const model = JSON.parse(decodedString)
  return model
}

export const encodeJson = (data) => {
  var value = JSON.stringify(data)
  const utf8Array = new TextEncoder().encode(value);
  const base64String = btoa(String.fromCharCode(...utf8Array));
  return base64String
  // return encodeURIComponent(base64String);
}

export const isLoggedIn = () => {
  var token = localStorage.getItem("X-API-TOKEN");
  return token !== undefined && token !== null;
}

export const imageDataURLtoFile = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const filename = "user_image.jpg";

    return new File([u8arr], filename, { type: mime });
  };

export const getCachedMembership = async () => {
  var v = localStorage.getItem("membership");
  if (v === undefined || v === null) {
    try {
      var response = await HttpGet("/user/membership");
      var result = response.data.data.result;
      // console.log("membership: " + result);
      localStorage.setItem("membership", result + "");
      v = result;
    } catch (error) {
      console.log(error)
    }
  }
  return v;
}

export const setCachedWorld = (world) => {
  const key = "world:data:" + world.uuid
  localStorage.setItem(key, JSON.stringify(world))
}


export const getCachedWorld = (worldUuid) => {
  const key = "world:data:" + worldUuid
  const v = localStorage.getItem(key)
  if (v === undefined || v === null) {
    return null
  }
  try {
    return JSON.parse(v)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const setCurrentPlayerInCache = (dm) => {
  localStorage.setItem("current_player", encodeJson(dm))
}

export const getCurrentPlayerFromCache = () => {
  const v = localStorage.getItem("current_player")
  if (v === undefined || v === null) {
    return null
  }
  const p = decodeToJson(v)
  return p
}

export const convertToLocalDateTime = (timestamp) => {
  // Create a Date object from the timestamp string
  const date = new Date(timestamp);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Get the local datetime string
  const localDateTime = date.toLocaleString();

  return localDateTime;
};

export const getCurrentWorldId = () => {
  const key = `current-world-id`
  const v = localStorage.getItem(key)
  return v
}

export const setCurrentWorldId = (worldId) => {
  const key = `current-world-id`
  localStorage.setItem(key, worldId)
}

export const getCurrentWorld = () => {
  const worldId = getCurrentWorldId()
  return getCachedWorld(worldId)
}

export const loadPlayer = (dmUuid, callback) => {
  HttpGet("/digitalmodel/model/get/" + dmUuid)
  .then((response)=>{
      const dm = response.data.data.result
      // console.log(dm)
      setCurrentPlayerInCache(dm)
      PlayerEnergyEventEmitter.emit('updateEnergy', {"energy":dm.energy, "max_energy":dm.max_energy})
      PlayerLevelEventEmitter.emit('updateLevel', {"level":dm.level})
      if (callback) {
        callback(dm)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

export const updatePlayerLevel = (level) => {
  PlayerLevelEventEmitter.emit('updateLevel', {"level":level})
}

export const isCurrentPlayerAbleToAct = () => {
  const player = getCurrentPlayerFromCache()
  if (player === null) {
    return false
  }

  return player.energy > 0
}

export const getQuestSummary = (quest) => {
  if (GetLocale() === "zh-cn") {
    return quest.substring(0, 30) + "..."
  }
  return quest.substring(0, 100) + "..."
}

export const getTextLines = (content) => {
  var lines = []
  var arr = content.split('\n')
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === "\n") {
      continue
    }
    lines.push(arr[i])
  }
  return lines
}

export const getImageUrl = (url) => {
  if (url !== "") {
    return url
  }
  return "/image/candidate.jpg"
}

export const getTimeStringFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}