import { getCurrentWorld } from "./service/common/util";

function toKey(s) {
    return s.trim().toLowerCase().replaceAll(" ", "_").replaceAll('"', '')
}

function setLocale(locale) {
    localStorage.setItem('locale', locale)
}

function getLocale() {
    const urlParams = new URLSearchParams(window.location.search);
    var locale = urlParams.get('locale');
    if (locale) {
        return locale
    }

    const world = getCurrentWorld()
    if (world !== null && world !== undefined) {
        return world.language
    }

    locale = localStorage.getItem('locale')
    if (locale !== null && locale !== undefined) {
        return locale
    }

    locale = navigator.language
    if (!locale) {
        locale = navigator.browserLanguage
    }
    return locale.toLowerCase()
}

const translation_cn = {
    
}

const translation_en = {
}

const world_translation = {
}



const T = (s) => {
    const currentWorld = getCurrentWorld()
    if (currentWorld !== null && currentWorld !== undefined) {
        var translation = world_translation[currentWorld.uuid]
        if  (translation !== null && translation !== undefined) {
            if (translation[s] !== undefined) {
                return translation[s]
            }
            var k = toKey(s)
            if (translation[k] !== undefined) {
                return translation[k]
            }    
        }
    }

    var translation = translation_en;

    var locale = getLocale()    
    if (locale === "zh-hans" || locale === "zh-cn") {
        translation = translation_cn;
    }

    if (translation[s] !== undefined) {
        return translation[s]
    }

    var k = toKey(s)
    var v = translation[k];
    return v ? v : s;
}

export const GetLocale = ()=>{
    const locale = getLocale()
    if (locale === "zh-hans" || locale === "zh-cn") {
        return "zh-cn"
    }
    return "en-us"
}

export const translate = (s) => {
    return T(s)
}

export default T